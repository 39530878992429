import React, {
    useContext,
    useState,
    useReducer,
    useRef,
    RefObject,
} from "react";
import styled from "styled-components";
import { NetworkStatus, useQuery } from "@apollo/client";

import { Content } from "../AppRoot";
import Container from "../grid/Container";
import Icon from "../svg/Icon";
import { CIRCLE, CHECK, TIMES } from "../../config/icons";
import ShareButtons from "./ShareButtons";
import mediaQueries from "../../helpers/mediaQueries";
import { DOMAIN_URL, ASSETS_URL } from "../../config/env";
import {
    GetQuizData,
    GetQuizVariables,
    GET_QUIZ,
} from "../../graphql/queries/games";
import { QuizContent, QuizQuestionContent } from "../../models/games";
import { IImage } from "../../models/gallery";
import ImageItem from "../images/ImageItem";
import Spinner from "../Spinner";
import { EngagementInfo } from "../../models/_common";

const QuizSection: React.FC = () => {
    return (
        <Section id="quiz">
            <QuizLoader />
        </Section>
    );
};

const QuizLoader: React.FC = () => {
    const quizContainerRef = useRef<HTMLDivElement>(null);
    const { code } = useContext(Content);

    const { data, error, loading, refetch, networkStatus } = useQuery<
        GetQuizData,
        GetQuizVariables
    >(GET_QUIZ, {
        variables: {
            id: "check-your-knowledge-of-solar-and-lunar-eclipses",
            langCode: code,
        },
        notifyOnNetworkStatusChange: true,
    });

    if (loading)
        return (
            <Container>
                <QuizContainer>
                    <Spinner />
                </QuizContainer>
            </Container>
        );

    if (error) return null;

    if (data && data.quiz) {
        return (
            <Container ref={quizContainerRef}>
                <QuizContainer>
                    <Quiz
                        key={code}
                        content={data.quiz.content}
                        quizContainerRef={quizContainerRef}
                        shareUrl={`${DOMAIN_URL}/${code}`}
                        refresh={refetch}
                        refreshing={networkStatus === NetworkStatus.refetch}
                        engagementInfo={data.quiz.engagementInfo}
                    />
                </QuizContainer>
            </Container>
        );
    }
    return null;
};

interface QuizProps {
    content: QuizContent<IImage>;
    quizContainerRef: RefObject<HTMLDivElement>;
    shareUrl: string;
    refresh: () => void;
    refreshing: boolean;
    engagementInfo: EngagementInfo;
}
type QuizReduceAction = { type: "correct" } | { type: "reset" };

type QuizReducer = (state: number, action: QuizReduceAction) => number;

type QuizStage = "start" | "result" | number;

const Quiz: React.FC<QuizProps> = ({
    content,
    shareUrl,
    quizContainerRef,
    refresh,
    refreshing,
    engagementInfo,
}) => {
    const [stage, setStage] = useState<QuizStage>("start");
    const { questions } = content;
    const [correctAnswersCounter, dispatch] = useReducer<QuizReducer>(
        (state, action) => {
            switch (action.type) {
                case "correct":
                    return state + 1;
                case "reset":
                    return 0;
                default:
                    throw new Error("Invalid action type.");
            }
        },
        0,
    );

    const scrollToTop = () => {
        if (quizContainerRef.current) {
            const { top } = quizContainerRef.current.getBoundingClientRect();
            window.scrollTo(0, window.scrollY - 64 + top);
        }
    };
    if (refreshing) {
        return (
            <Container>
                <QuizContainer>
                    <Spinner />
                </QuizContainer>
            </Container>
        );
    }
    if (stage === "start") {
        return (
            <>
                <QuizIcon>
                    <Img src={`${ASSETS_URL}/images/icons/quiz-icon.png`} />
                </QuizIcon>
                <QuizTitle>{content.title}</QuizTitle>
                <Text>{content.text}</Text>
                <ImageItemWrapper>
                    <ImageItem
                        image={content.mediaItem}
                        showPlaceholder={false}
                        srcSetSizes={{
                            laptop: 616,
                            tablet: 534,
                        }}
                    />
                </ImageItemWrapper>
                <NavButton
                    id="next-question-quiz-button"
                    data-next-question-id={questions[0].id}
                    data-stage={1}
                    data-result={correctAnswersCounter}
                    onClick={() => {
                        setStage(0);
                        scrollToTop();
                    }}
                    highlighted={true}
                >
                    {content.startButton}
                </NavButton>
                <ShareButtons
                    shareMessage={content.title}
                    pageUrl={shareUrl}
                    hashAnchor="quiz"
                    shareCampaign="share-quiz"
                    typename="Quiz"
                    engagementInfo={engagementInfo}
                />
            </>
        );
    }

    if (stage === "result") {
        const { summaries, shareMessage } = content;
        const sum = [...summaries]
            .sort((a, b) => (a.minCorrect < b.minCorrect ? 1 : -1))
            .find(summary => summary.minCorrect <= correctAnswersCounter);
        return (
            <>
                <Result>
                    {correctAnswersCounter} / {questions.length}
                </Result>
                <QuizTitle>{sum?.title}</QuizTitle>
                <Text id="quiz-result-text">{sum?.text}</Text>
                <ButtonsTitle>{content.shareYourResult}</ButtonsTitle>
                <ShareButtons
                    shareMessage={shareMessage
                        .replace("%CORRECT%", `${correctAnswersCounter}`)
                        .replace("%TOTAL%", `${questions.length}`)}
                    pageUrl={shareUrl}
                    hashAnchor="quiz"
                    shareCampaign="share-quiz-result"
                    value={correctAnswersCounter}
                    typename="Quiz"
                    engagementInfo={engagementInfo}
                />
                <ImageItemWrapper>
                    <ImageItem
                        image={content.mediaItem}
                        showPlaceholder={false}
                        srcSetSizes={{
                            laptop: 616,
                            tablet: 534,
                        }}
                    />
                </ImageItemWrapper>

                <NavButton
                    id="reset-quiz-button"
                    data-result={correctAnswersCounter}
                    data-questions-length={questions.length}
                    onClick={() => {
                        dispatch({ type: "reset" });
                        setStage(0);
                        refresh();
                        scrollToTop();
                    }}
                    highlighted={true}
                >
                    {content.restartButton}
                </NavButton>
            </>
        );
    }

    return (
        <>
            <StageNumber>
                {stage + 1} / {questions.length}
            </StageNumber>
            <Question
                key={stage}
                stage={stage}
                result={correctAnswersCounter}
                question={questions[stage]}
                nextQuestionId={
                    questions[stage + 1] ? questions[stage + 1].id : ""
                }
                questionsLength={questions.length}
                isLast={stage === questions.length - 1}
                onNextText={
                    stage + 1 < questions.length
                        ? content.nextQuestionButton
                        : content.showResultButton
                }
                onNext={() => {
                    if (stage === questions.length - 1) {
                        setStage("result");
                    } else {
                        setStage(stage + 1);
                    }
                    scrollToTop();
                }}
                onPickCorrect={() => dispatch({ type: "correct" })}
            />
        </>
    );
};

interface QuestionProps {
    question: QuizQuestionContent<IImage>;
    isLast: boolean;
    stage: number;
    result: number;
    nextQuestionId: string;
    questionsLength: number;
    onNextText: string;
    onPickCorrect: () => void;
    onNext: () => void;
}

const Question: React.FC<QuestionProps> = ({
    question,
    isLast,
    onNext,
    stage,
    result,
    nextQuestionId,
    questionsLength,
    onNextText,
    onPickCorrect,
}) => {
    const [answered, setAnswered] = useState<string | null>(null);

    return (
        <>
            <Title>{question.text}</Title>
            <ImageItemWrapper>
                <ImageItem
                    image={question.mediaItem}
                    showPlaceholder={false}
                    srcSetSizes={{
                        laptop: 616,
                        tablet: 534,
                    }}
                />
                {isLast ? (
                    <NextButton
                        id={`show-result-quiz-button`}
                        data-question-id={question.id}
                        data-result={result}
                        data-questions-length={questionsLength}
                        onClick={onNext}
                        isHidden={answered === null}
                    >
                        {onNextText}
                    </NextButton>
                ) : (
                    <NextButton
                        id={`next-question-quiz-button`}
                        data-question-id={question.id}
                        data-next-question-id={nextQuestionId}
                        data-stage={stage + 2}
                        data-result={result}
                        data-questions-length={questionsLength}
                        onClick={onNext}
                        isHidden={answered === null}
                    >
                        {onNextText}
                    </NextButton>
                )}
            </ImageItemWrapper>
            <Variants>
                {question.variants.map(({ id, text, isCorrect }) => (
                    <VariantButton
                        key={`${question.id}${id}`}
                        id={`variant-quiz-button`}
                        data-question-id={question.id}
                        data-stage={stage + 1}
                        data-value={isCorrect ? "1" : "0"}
                        data-result={isCorrect ? result + 1 : result}
                        onClick={() => {
                            if (isCorrect) {
                                onPickCorrect();
                            }
                            setAnswered(id);
                        }}
                        disabled={answered !== null}
                        isPicked={answered === id}
                    >
                        <VariantIcon
                            state={
                                answered === null ||
                                (answered !== id && !isCorrect)
                                    ? "empty"
                                    : isCorrect
                                    ? "correct"
                                    : "wrong"
                            }
                        >
                            <Icon
                                path={
                                    answered === null ||
                                    (answered !== id && !isCorrect)
                                        ? CIRCLE
                                        : isCorrect
                                        ? CHECK
                                        : TIMES
                                }
                            />
                        </VariantIcon>
                        {text}
                    </VariantButton>
                ))}
            </Variants>
            <AnswerComment isHidden={answered === null}>
                <Text>{question.answerComment}</Text>
            </AnswerComment>
        </>
    );
};

const Section = styled.section`
    width: 100%;
    position: relative;
    padding: 3rem 0 1rem 0;
`;

const QuizContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid ${({ theme }) => theme.colors.secondary};
    border-radius: 20px;
    padding: 1rem;
    max-width: 650px;
    width: 100%;
    margin: 0 auto 1rem;
`;

const Img = styled.img`
    width: 150px;
    height: 60px;
`;

const QuizIcon = styled.div`
    display: flex;
    justify-content: center;
`;

const Title = styled.h2`
    font-size: 1rem;

    margin: 0.5rem 0;

    ${mediaQueries.mediumPhone(`font-size: 1.25rem;`)}

    ${mediaQueries.tablet(`font-size: 1.5rem;`)}
`;
const QuizTitle = styled(Title)`
    font-size: 1.25rem;

    ${mediaQueries.mediumPhone(`font-size: 1.5rem;`)}

    ${mediaQueries.tablet(`font-size: 1.75rem;`)}
`;

const Text = styled.div`
    font-size: 0.9rem;
    color: ${({ theme }) => theme.colors.secondary};
    font-weight: 300;
    margin: 0.5rem 0;

    ${mediaQueries.mediumPhone(`font-size: 1rem;`)}
    ${mediaQueries.tablet(`font-size: 1.25rem;`)}
`;

const Result = styled.div`
    text-align: center;
    margin: 20px 0;
    font-size: 3rem;
    font-weight: 300;
    color: ${({ theme }) => theme.colors.secondary};
`;

interface NavButtonProps {
    highlighted?: boolean;
}

const NavButton = styled.button<NavButtonProps>`
    background: ${({ theme: { colors }, highlighted }) =>
        highlighted
            ? `linear-gradient(
        90deg,
        ${colors.gradient} 25%,
        ${colors.accent} 75%
    )`
            : colors.nextButton};
    color: ${({ theme }) => theme.colors.primary};
    border: 0;
    border-radius: 10px;
    padding: 10px 30px;
    margin: 0.5rem;
    width: 100%;
    font-size: 1.25rem;
    font-weight: 500;
    cursor: pointer;
`;
const NextButton = styled(NavButton)<{ isHidden: boolean }>`
    width: calc(100% - 20px);
    display: ${({ isHidden }) => (isHidden ? "none" : "block")};
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    margin: 0;
    bottom: 10px;
    font-size: 1rem;
    ${mediaQueries.tablet(`font-size: 1.25rem;`)}
`;

const Variants = styled.div`
    width: 100%;
    margin: 0 0;
`;

interface AnswerCommentProps {
    isHidden: boolean;
}

const AnswerComment = styled.div<AnswerCommentProps>`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: ${({ isHidden }) => (isHidden ? "0" : "auto")};
    overflow: hidden;
    transition: all 0.2s;
`;

interface VariantButtonProps {
    isPicked: boolean;
}

const VariantButton = styled.button<VariantButtonProps>`
    background-color: ${({ isPicked, theme }) =>
        isPicked ? `${theme.colors.secondary}66` : "transparent"};
    border: 1px solid ${({ theme }) => theme.colors.secondary};
    border-radius: 10px;
    padding: 10px;
    margin: 0.5rem 0;
    color: #fff;
    display: flex;
    align-items: center;
    transition: all 0.2s;
    width: 100%;
    cursor: pointer;

    font-size: 1rem;
    ${mediaQueries.tablet(`font-size: 1.25rem;`)}
`;

interface VariantIconProps {
    state: "empty" | "correct" | "wrong";
}

const VariantIcon = styled.span<VariantIconProps>`
    border: 0;
    width: 20px;
    height: 20px;
    margin: 0 20px 0 0;
    color: ${({ state, theme }) =>
        state === "empty"
            ? theme.colors.secondary
            : state === "correct"
            ? "green"
            : "red"};
    transition: all 0.1s;
`;

const StageNumber = styled.div`
    font-size: 0.8rem;
    width: 100%;

    ${mediaQueries.tablet(`
        padding: 5px;
        font-size: 1rem;
    `)}
`;
const ImageItemWrapper = styled.div`
    position: relative;
    width: 100%;
`;
const ButtonsTitle = styled.div`
    color: inherit;
    font-size: 1.1rem;
    font-weight: 300;
    margin: 1rem 0 -0.5rem;
    transition: all 0.2s ease-in-out;
`;
export default QuizSection;
