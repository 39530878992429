// Locales:
import en from "./locales/en.json";
import fr from "./locales/fr.json";
import de from "./locales/de.json";
import es from "./locales/es.json";
import it from "./locales/it.json";
import ja from "./locales/ja.json";
import ko from "./locales/ko.json";
import nl from "./locales/nl.json";
import ru from "./locales/ru.json";
import tr from "./locales/tr.json";
import pt from "./locales/pt.json";
import hi from "./locales/hi.json";
import id from "./locales/id.json";
import ms from "./locales/ms.json";
import th from "./locales/th.json";
import zhHans from "./locales/zh-Hans.json";
import zhHant from "./locales/zh-Hant.json";

export type Lang =
    | "de"
    | "en"
    | "es"
    | "fr"
    | "hi"
    | "id"
    | "it"
    | "ja"
    | "ko"
    | "ms"
    | "nl"
    | "pt"
    | "ru"
    | "th"
    | "tr"
    | "zh-Hans"
    | "zh-Hant";

export interface LocalizedContent {
    dict: {
        [key: string]: string;
    };
    getAppButton: string;
    language: string;
    footer: {
        privacy: string;
        terms: string;
    };
    countdown: {
        totalLunar: string;
        partialLunar: string;
        penumbralLunar: string;
        totalSolar: string;
        partialSolar: string;
        annularSolar: string;
    };
    storeBadges: StoreBadgesAlts;
    meta: {
        description: string;
    };
    animationSection: {
        subtitle: string;
    };
    quizSection?: QuizSectionContent;
    productSection: {
        productImageAlt: string;
        description: string;
        description2: string;
    };
    videoSection: {
        title?: string;
        link: string;
    };
    featureIcons: {
        caption: string;
        description: string;
        icon: string;
    }[];
    gallerySection: {
        title: string;
        subtitle: string;
    };
    galleryImagesMeta: ImageMeta[];
}

export interface LocalizedContentWithCode extends LocalizedContent {
    code: Lang;
}

export interface ImageMeta {
    folder: string;
    alt: string;
}

export interface StoreBadgesAlts {
    appStoreAlt: string;
    googlePlayAlt: string;
}
export interface QuizSectionContent {
    title: string;
    text: string;
    startButton: string;
    showResultButton: string;
    restartButton: string;
    nextQuestionButton: string;
    shareMessage: string;
    answers: string[];
    questions: QuizQuestion[];
    summaries: QuizSummaries;
}

export interface QuizQuestion {
    id: string;
    text: string;
    correctAnswerIndex: number;
    answerComment: string;
    variants: number[];
}

interface QuizSummaries {
    good: QuizSummary;
    medium: QuizSummary;
    bad: QuizSummary;
}

interface QuizSummary {
    title: string;
    text: string;
}
interface Content {
    [key: string]: LocalizedContent;
    de: LocalizedContent;
    en: LocalizedContent;
    es: LocalizedContent;
    fr: LocalizedContent;
    hi: LocalizedContent;
    id: LocalizedContent;
    it: LocalizedContent;
    ja: LocalizedContent;
    ko: LocalizedContent;
    ms: LocalizedContent;
    nl: LocalizedContent;
    pt: LocalizedContent;
    ru: LocalizedContent;
    th: LocalizedContent;
    tr: LocalizedContent;
    "zh-Hans": LocalizedContent;
    "zh-Hant": LocalizedContent;
}

const content: Content = {
    de,
    en,
    es,
    fr,
    hi,
    id,
    it,
    ja,
    ko,
    ms,
    nl,
    pt,
    ru,
    th,
    tr,
    "zh-Hans": zhHans,
    "zh-Hant": zhHant,
};

export default content;
