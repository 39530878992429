import React, { useContext } from "react";
import styled from "styled-components";

// Helpers:
import mediaQueries from "../../helpers/mediaQueries";

// Components:
import Container from "../grid/Container";
import { Content } from "../AppRoot";
import { PRIVACY_LINK, TERMS_LINK } from "../../config/content";
import StoreBadges from "../main/StoreBadges";
import { makeStoreBadges } from "../../helpers/utils";
import { APPLE_APP_ID, GOOGLE_PLAY_APP_ID } from "../../config/env";

interface Props {
    showButtons?: boolean;
}

const Footer: React.SFC<Props> = ({ showButtons }) => {
    const {
        footer: { privacy, terms },
        storeBadges,
        code,
    } = useContext(Content);

    return (
        <FooterSection>
            <Container>
                {!!showButtons && (
                    <StoreBadges
                        storeBadges={makeStoreBadges(
                            code,
                            storeBadges,
                            {
                                iosAppId: APPLE_APP_ID,
                                androidAppId: GOOGLE_PLAY_APP_ID,
                            },
                            "btn",
                            "footer",
                        )}
                    />
                )}
                <LinksContainer>
                    <Link id="footer-privacy-link" href={PRIVACY_LINK}>
                        {privacy}
                    </Link>
                    <Link id="footer-terms-link" href={TERMS_LINK}>
                        {terms}
                    </Link>
                </LinksContainer>
                <Copyright>
                    {`© ${new Date().getFullYear()} `}
                    <ExternalLink href="https://www.vitotechnology.com">
                        Vito Technology, Inc.
                    </ExternalLink>
                </Copyright>
            </Container>
        </FooterSection>
    );
};

// Styles for Styled Components:
const FooterSection = styled.footer`
    padding: 1rem 0;
    z-index: 100;
    background-color: ${({ theme }) => theme.colors.background};
`;

const LinksContainer = styled.div`
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
`;

const Link = styled.a`
    margin: 0.5rem 0.5rem;
    color: ${({ theme }) => theme.colors.primary};
    font-size: 0.75rem;
    text-align: center;
    text-decoration: none;
    ${mediaQueries.laptop(`&:hover { text-decoration: underline; }`)}
`;

const Copyright = styled.div`
    margin: 0.5rem 0;
    color: #acacac;
    font-size: 0.75rem;
    text-align: center;
`;

const ExternalLink = styled.a`
    color: #acacac;
    text-decoration: none;
    ${mediaQueries.laptop(`&:hover { text-decoration: underline; }`)}
`;

export default Footer;
