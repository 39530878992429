import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";

import * as serviceWorker from "./serviceWorker";

// Containers:
import AppRoot from "./components/AppRoot";

// Components:
import ScrollToTop from "./components/ScrollToTop";
import { ApolloProvider } from "@apollo/client";
import client from "./graphql/client";

const history = createBrowserHistory();

ReactDOM.hydrate(
    <ApolloProvider client={client}>
        <Router history={history}>
            <ScrollToTop />
            <AppRoot />
        </Router>
    </ApolloProvider>,
    document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
