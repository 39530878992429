import gql from "graphql-tag";
import { IImage } from "../../models/gallery";
import { Quiz, QuizContent } from "../../models/games";

import { Lang } from "../../i18n/content";

export interface GetQuizVariables {
    langCode: Lang;
    id: string;
}

export interface GetQuizData {
    quiz: Quiz<QuizContent<IImage>> | null;
}

export const thumbnail = gql`
    fragment thumbnail on MediaFile {
        fileName
        width
        height
        length
        contentType
    }
`;

export const GET_QUIZ = gql`
    query getQuiz($langCode: LangCode, $id: QuizID!) {
        quiz(id: $id) {
            id
            slug
            date
            updatedAt
            questionsNumber
            content(langCode: $langCode) {
                title
                text
                mediaItem {
                    id
                    ... on Image {
                        content(langCode: $langCode) {
                            name
                            sourceUrl
                            thumbnails {
                                ...thumbnail
                            }
                        }
                    }
                }
                startButton
                nextQuestionButton
                showResultButton
                restartButton
                shareMessage
                shareYourResult
                questions {
                    id
                    text
                    answerComment
                    mediaItem {
                        id
                        ... on Image {
                            content(langCode: $langCode) {
                                name
                                sourceUrl
                                thumbnails {
                                    ...thumbnail
                                }
                            }
                        }
                    }
                    variants {
                        id
                        text
                        answerComment
                        isCorrect
                        pickedCount
                    }
                }
                summaries {
                    title
                    text
                    minCorrect
                }
            }
            engagementInfo {
                facebook
                messenger
                twitter
                telegram
                whatsapp
                native
            }
        }
    }
    ${thumbnail}
`;
