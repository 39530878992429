import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import { motion, useViewportScroll, useTransform } from "framer-motion";
import mediaQueries from "../../helpers/mediaQueries";
import { ASSETS_URL } from "../../config/env";

export const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState(0);

    useEffect(() => {
        if (typeof window === "undefined") {
            return;
        }

        const handleResize = () => {
            setWindowSize(window.innerHeight);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount and unmount

    return windowSize;
};

const SolarEclipse: React.SFC = () => {
    const skyRef = useRef<HTMLDivElement>(null);
    const [height, setHeight] = useState<number>(0);
    const { scrollY } = useViewportScroll();

    const windowSize = useWindowSize();

    const rotate = useTransform(
        scrollY,
        [0, height * 0.4, height * 0.4, height * 0.95],
        [45, 5, 5, 0],
    );
    const opacity = useTransform(
        scrollY,
        [height * 0.5, height * 0.95],
        [1, 0],
    );
    const coronaOpacity = useTransform(
        scrollY,
        [height * 0.75, height * 0.95],
        [0, 1],
    );

    useEffect(() => {
        if (skyRef.current) {
            setHeight(skyRef.current.clientHeight * 2);
        }
    }, [windowSize]);

    return (
        <SkyContainer>
            <Sky style={{ opacity }} ref={skyRef} />
            <svg viewBox="0 0 400 400" width="400" height="400">
                <defs>
                    <motion.clipPath
                        style={{
                            rotate,
                            originX: "0px",
                            originY: "0px",
                        }}
                        id="mask"
                    >
                        <path d="M -400 -400 v 800 h 800 v -800 z M 100 200 a 9 9 0 1 1 200 0 a 9 9 0 0 1 -200 0 z" />
                    </motion.clipPath>
                    <filter
                        id="drop-shadow"
                        x="-100%"
                        y="-100%"
                        width="300%"
                        height="300%"
                    >
                        <feGaussianBlur in="SourceAlpha" stdDeviation="8" />
                        <feOffset dx="0" dy="0" result="offsetblur" />
                        <feFlood floodColor="#fff" />
                        <feComposite in2="offsetblur" operator="in" />
                        <feMerge>
                            <feMergeNode />
                            <feMergeNode in="SourceGraphic" />
                        </feMerge>
                    </filter>
                </defs>
                <g filter="url(#drop-shadow)">
                    <circle
                        clipPath="url(#mask)"
                        r="99"
                        cx="200"
                        cy="200"
                        fill="white"
                    />
                </g>

                <motion.image
                    x="2"
                    y="2"
                    width="396"
                    height="396"
                    xlinkHref={`${ASSETS_URL}/images/cor11.png`}
                    style={{ opacity: coronaOpacity }}
                />
            </svg>
        </SkyContainer>
    );
};

// Styles for styled-components
const SkyContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: calc(100vh - 3rem);
    display: flex;
    justify-content: center;
    align-items: center;
    will-change: opacity;
    transition: opacity 100ms;
    transform: translateZ(0);
    transform-style: preserve-3d;
    backface-visibility: hidden;
    perspective: 1000;

    ${mediaQueries.landscapePhone(`padding-left: 50%;`)}
`;

export const Sky = styled(motion.div)`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
    will-change: opacity;
    transform: translateZ(0);
    transform-style: preserve-3d;
    backface-visibility: hidden;
    perspective: 1000;
    background-color: ${({ theme }) => theme.colors.blueSky};
`;

// const Image = styled(motion.image)``;
//
// const Svg = styled.svg``;

// const CoronaContainer = styled.div`
//     position: absolute;
//     top: 0;
//     left: 0;
//     bottom: 0;
//     right: 0;
//     width: 100%;
//     height: 100%;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     perspective: 1000;
// `;

// const Corona = styled(motion.img)`
//     opacity: 0;
//     width: 620x;
//     height: 620px;
//     color: #ffe;
//     margin: auto;
//     transform: translateZ(0);
//     will-change: opacity;
//     backface-visibility: hidden;
//     perspective: 1000;
// `;

export default SolarEclipse;
