import {
    ApolloClient,
    ApolloLink,
    HttpLink,
    InMemoryCache,
} from "@apollo/client";
import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries";
import { sha256 } from "crypto-hash";
import { API_URL, VITO_APP_ID } from "../config/env";
import { possibleTypes } from "./possible-types";

import resolvers from "./resolvers";

// interface WindowWithApolloState extends Window {
//     __APOLLO_STATE__: NormalizedCacheObject;
// }

const persistedQueryLink = createPersistedQueryLink({
    sha256,
});

const httpLink = new HttpLink({
    uri: API_URL,
    headers: {
        "X-Vito-App-Id": VITO_APP_ID,
    },
});

const cache = new InMemoryCache({
    possibleTypes,
}).restore((window as any).__APOLLO_STATE__);

const myAppLink = ApolloLink.from([persistedQueryLink, httpLink]);

const client = new ApolloClient({
    link: myAppLink,
    cache,
    resolvers,
});

export default client;
