import { useQuery } from "@apollo/client";
import React, { useContext } from "react";
import {
    ConfigData,
    GET_COMMON_CONTENT,
    GetCommonContentData,
    GetCommonContentVariables,
} from "../graphql/queries/common";
import { Content } from "./AppRoot";

interface Props {
    children: React.ReactNode;
}

export const ConfigContext = React.createContext<ConfigData>(undefined!);

const ConfigContextProvider: React.FC<Props> = ({ children }) => {
    const { code } = useContext(Content);
    const { data } = useQuery<GetCommonContentData, GetCommonContentVariables>(
        GET_COMMON_CONTENT,
        {
            variables: {
                langCode: code,
            },
        },
    );
    if (data && data.app) {
        return (
            <ConfigContext.Provider value={data.app}>
                {children}
            </ConfigContext.Provider>
        );
    }
    return null;
};

export default ConfigContextProvider;
