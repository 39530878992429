export const solarEclipseSteps = {
    start: "0%",
    closeToFull: "58%",
    startFullEclipse: "67%",
    coronaAppears: "74%",
    endFullEclipse: "100%",
};

export const moonEclipseSteps = {
    start: 0,
    closeToMax: 38,
    max: 45,
    endOfMax: 60,
    end: 100,
};

export const ANIMATION_LENGTH = "20s";

export const ECLIPSE_CHANGE_DELAY = 86400000;
