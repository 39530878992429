import React from "react";
import styled from "styled-components";

// Components:
import Container from "../grid/Container";

// Types & Interfaces:
import { Lang } from "../../i18n/content";
import Logo from "../svg/Logo";
import { useHistory, useLocation } from "react-router-dom";
import { preserveSearch } from "../../helpers/utils";

interface Props {
    lang: Lang;
    languages: { langCode: Lang; langTitle: string }[];
}

const Header: React.SFC<Props> = ({ lang, languages }) => {
    const history = useHistory();
    const { search } = useLocation();
    const onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        history.push(`/${event.target.value}${preserveSearch(search)}`);
    };

    const selectWidth =
        languages.find(({ langCode }) => langCode === lang)!.langTitle.length *
        10;
    return (
        <HeaderSection>
            <Container>
                <HeaderLayout>
                    <Logo />
                    <LangPicker>
                        <Arrow />
                        <Select
                            width={selectWidth}
                            id="lang-picker"
                            defaultValue={lang}
                            onChange={onChange}
                        >
                            {languages.map(language => (
                                <Option
                                    value={language.langCode}
                                    key={language.langCode}
                                >
                                    {language.langTitle}
                                </Option>
                            ))}
                        </Select>
                    </LangPicker>
                </HeaderLayout>
            </Container>
        </HeaderSection>
    );
};

// Styles for Styled Components:
const HeaderSection = styled.header`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0.95;
    z-index: 100;
    box-sizing: border-box;
    display: flex;
    height: 3rem;
    background-image: ${({ theme: { colors } }) => `linear-gradient(
        90deg,
        ${colors.accent} 25%,
        ${colors.gradient} 75%
    )`};
`;

const LangPicker = styled.div`
    display: flex;
    height: 100%;
`;

const HeaderLayout = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

// Styles for Styled Components:
const Arrow = styled.div`
    position: relative;
    margin-right: 0.3rem;
    width: 0.5rem;

    &::after {
        content: " ";
        position: absolute;
        top: 50%;
        left: 0;
        width: 0;
        height: 0;
        transform: translateY(-50%);
        border-style: solid;
        border-width: 7px 5px 0 5px;
        border-color: transparent;
        border-top-color: inherit;
    }
`;

interface SelectProps {
    width: number;
}

const Select = styled.select<SelectProps>`
    padding: 0;
    color: ${({ theme }) => theme.colors.primary};
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: transparent;
    width: ${({ width }) => width}px;
    min-width: 4.5rem;
    border: 0;
    border-width: 0 0 2px 0;
    height: 3rem;
    border-radius: 0;
    font-size: 1rem;
    padding-right: 5px;
`;

const Option = styled.option`
    color: ${({ theme }) => theme.colors.gradient};
`;

export default Header;
