import React from "react";
import Helmet from "react-helmet";

// Data:
import content from "../../i18n/content";

interface Props {
    domainURL: string;
}

export const HtmlAlternateLinks: React.SFC<Props> = ({ domainURL }) => {
    const langs = Object.keys(content);

    return (
        <Helmet>
            {langs.map(lang => (
                <link
                    key={lang}
                    rel="alternate"
                    hrefLang={lang}
                    href={`${domainURL}/${lang}`}
                />
            ))}
        </Helmet>
    );
};

export default HtmlAlternateLinks;
