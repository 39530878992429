import React from "react";
import { Helmet } from "react-helmet";
import { ASSETS_URL } from "../../config/env";

const HtmlFonts: React.SFC = () => (
    <Helmet>
        <style>
            {`
                @font-face {
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 100;
                    font-display: swap;
                    src: local('Lato Thin'), local('Lato-Thin'), url(${ASSETS_URL}/fonts/Lato-Thin.woff2) format('woff2');
                  }
                @font-face {
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 300;
                    font-display: swap;
                    src: local('Lato Light'), local('Lato-Light'), local('sans-serif-light'), url(${ASSETS_URL}/fonts/Lato-Light.woff2) format('woff2');
                }
                @font-face {
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 400;
                    font-display: swap;
                    src: local('Lato'), local('Lato-Regular'), local('sans-serif'), url(${ASSETS_URL}/fonts/Lato-Regular.woff2) format('woff2');
                }
                @font-face {
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 500;
                    font-display: swap;
                    src: local('Lato Medium'), local('Lato-Medium'), local('sans-serif-medium'), url(${ASSETS_URL}/fonts/Lato-Medium.woff2) format('woff2');
                }`}
        </style>
    </Helmet>
);

export default HtmlFonts;
