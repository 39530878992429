import React from "react";

const SWLogo: React.SFC = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="45"
        height="30"
        viewBox="0 0 300 200"
        fill="currentColor"
    >
        <rect
            x="138"
            y="63"
            height="8"
            width="54"
            rx="4"
            transform="rotate(77 138 63)"
        />
        <circle cx="150" cy="131" r="10" />
        <rect
            x="81"
            y="177"
            height="8"
            width="68"
            rx="4"
            transform="rotate(-36 81 177)"
        />
        <circle cx="69" cy="188" r="12" />
        <rect
            x="26"
            y="139"
            height="8"
            width="49"
            rx="4"
            transform="rotate(46 26 139)"
        />
        <circle cx="12" cy="129" r="12" />
        <rect
            x="25"
            y="116"
            height="8"
            width="106"
            rx="4"
            transform="rotate(-36 25 116)"
        />
        <circle cx="129" cy="46" r="14" />
        <rect
            x="145"
            y="34"
            height="8"
            width="49"
            rx="4"
            transform="rotate(-27 145 34)"
        />
        <circle cx="205" cy="10" r="10" />
        <rect
            x="222"
            y="8"
            height="8"
            width="52"
            rx="4"
            transform="rotate(13 222 8)"
        />
        <circle cx="288" cy="29" r="12" />
    </svg>
);

export default SWLogo;
