import gql from "graphql-tag";
import { thumbnail } from "./games";
import { IImage } from "../../models/gallery";
import { Lang } from "../../i18n/content";
export interface GetCommonContentVariables {
    langCode?: Lang;
}

type EclipseType =
    | "totalLunar"
    | "partialLunar"
    | "penumbralLunar"
    | "totalSolar"
    | "partialSolar"
    | "annularSolar";

export interface Eclipse {
    type: EclipseType;
    timestamp: number;
}

export interface ConfigData {
    config: {
        eclipses: Eclipse[];
    };
    mediaItems: (IImage | null)[];
    mediaItem: IImage | null;
}

export interface GetCommonContentData {
    app: ConfigData | null;
}

export const GET_COMMON_CONTENT = gql`
    query getCommonContent($langCode: LangCode) {
        app(id: "eclipse-site") {
            config
            mediaItems {
                id
                ... on Image {
                    content(langCode: $langCode) {
                        name
                        sourceUrl
                        thumbnails {
                            ...thumbnail
                        }
                    }
                }
            }
            mediaItem {
                id
                ... on Image {
                    content(langCode: $langCode) {
                        name
                        sourceUrl
                        thumbnails {
                            ...thumbnail
                        }
                    }
                }
            }
        }
    }
    ${thumbnail}
`;
