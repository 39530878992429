import React from "react";
import styled from "styled-components";
import { abbrevieteNumber } from "../../helpers/utils";
interface Props {
    count: number | null;
}
const SharingCounterBadge: React.FC<Props> = ({ count }) => {
    if (!count) return null;

    const abbrevieteCountValue = abbrevieteNumber(count);
    const countLength = abbrevieteCountValue.length;

    const rectWidth = (countLength: number) => {
        if (countLength === 1) {
            return 10;
        } else if (countLength === 2) {
            return 16;
        } else if (countLength === 3) {
            return 20;
        } else if (countLength >= 4) {
            return 23;
        }
        return 0;
    };

    return (
        <BadgeContainer className="sharing-counter-badge-container">
            <svg
                width={rectWidth(countLength)}
                height="100%"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                <rect
                    x="0"
                    y="0"
                    rx="5"
                    ry="5"
                    height="100%"
                    width="100%"
                    fill="rgb(244, 67, 54)"
                />
                <text
                    x="50%"
                    y="46%"
                    fill="#fff"
                    fontSize="8"
                    fontWeight="400"
                    textAnchor="middle"
                    dominantBaseline="central"
                >
                    {abbrevieteCountValue}
                </text>
            </svg>
        </BadgeContainer>
    );
};
const BadgeContainer = styled.div`
    position: absolute;
    display: flex;
    top: -4px;
    right: 13px;
    align-items: center;
    justify-content: center;
    padding: 0 3px;
    height: 10px;
    z-index: 1;
    flex-wrap: wrap;
    flex-direction: row;
    box-sizing: border-box;
    transform: scale(1) translate(50%, -50%);
    transform-origin: 100% 0%;
`;
export default SharingCounterBadge;
