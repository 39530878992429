import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Normalize } from "styled-normalize";

// Data:
import content, { LocalizedContentWithCode } from "../i18n/content";
import { GOOGLE_TAG_CONTAINER_ID, FACEBOOK_APP_ID } from "../config/env";

// Components:
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import Main from "./layout/Main";
import GlobalStyles from "./GlobalStyles";
import ProductSection from "./main/ProductSection";
import FeaturesSection from "./main/FeaturesSection";
import GallerySection from "./main/GallerySection";
import NotFound from "./NotFound";
import HtmlFonts from "./helmet/HtmlFonts";
import HtmlTitle from "./helmet/HtmlTitle";
import HtmlInit from "./helmet/HtmlInit";
import AnimationSection from "./main/AnimationSection";
// import VideoSection from "./main/VideoSection";
import HtmlGTag from "./helmet/HtmlGTag";
import MetaTags from "./MetaTags";
import ConfigContextProvider from "./ConfigContextProvider";

// Types & Interfaces:
import { Lang } from "../i18n/content";
import { ThemeProvider } from "styled-components";
import { theme2 } from "../config/themes";
import QuizSection from "./main/QuizSection";
import HtmlFacebookAppId from "./helmet/HtmlFacebookAppId";
import PromoAppContextProvider from "./main/getAppButton/PromoAppContextProvider";
import ButtonActions from "./main/getAppButton/ButtonActions";

const langParam = `/:lang(${Object.keys(content).join("|")})`;

const languages = Object.keys(content).map(lang => ({
    langCode: lang as Lang,
    langTitle: content[lang].language,
}));

const buildContent = (lang: Lang): LocalizedContentWithCode => ({
    quizSection: content["en"].quizSection,
    ...content[lang],
    code: lang,
});

export const Content = React.createContext(buildContent("en"));

// const setCookie = (ver: Version) => {
//     try {
//         const date = new Date(Date.now() + 90 * 24 * 60 * 60 * 1000);
//         document.cookie = `vitover=ver${ver}; expires=${date.toUTCString()}; path=/`;
//     } catch (e) {}
// };

// type Version = "1" | "2";

// const getVersion = (param: string | null): Version => {
//     switch (param) {
//         case "2":
//             return param;
//         default:
//             return "1";
//     }
// };

const AppRoot: React.SFC = () => {
    // const { search } = useLocation();
    // const params = new URLSearchParams(search);

    // const ver = getVersion(params.get("ver"));

    // setCookie(ver);

    return (
        <ThemeProvider theme={theme2}>
            <>
                <HtmlInit />
                <HtmlFonts />
                <HtmlGTag gTag={GOOGLE_TAG_CONTAINER_ID} />
                <HtmlFacebookAppId value={FACEBOOK_APP_ID} />
                <Normalize />
                <GlobalStyles />
                <Switch>
                    <Route
                        sensitive={true}
                        path={langParam}
                        render={({
                            match: {
                                params: { lang },
                            },
                        }) => (
                            <Content.Provider value={buildContent(lang)}>
                                <ConfigContextProvider>
                                <PromoAppContextProvider>
                                    <MetaTags />
                                    <Header lang={lang} languages={languages} />
                                    <Main>
                                        <ButtonActions />
                                        <AnimationSection />
                                        <QuizSection />
                                        <ProductSection />
                                        <FeaturesSection />
                                        <GallerySection />
                                    </Main>
                                    <Footer showButtons={true} />
                                </PromoAppContextProvider>
                                </ConfigContextProvider>
                            </Content.Provider>
                        )}
                    />
                    <Redirect exact={true} from="/" to="/en" />
                    <Route
                        exact={true}
                        render={() => (
                            <Content.Provider value={buildContent("en")}>
                                <HtmlTitle value={`404 - Page Not Found`} />
                                <Header lang={"en"} languages={languages} />
                                <Main>
                                    <NotFound />
                                </Main>
                                <Footer />
                            </Content.Provider>
                        )}
                    />
                </Switch>
            </>
        </ThemeProvider>
    );
};

export default AppRoot;
