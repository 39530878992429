import styled from "styled-components";

export type PointPosition = "20vh" | "25%" | "50%" | "75%" | "95%";

interface PointProps {
    position: PointPosition;
}

const AbsolutePoint = styled.div<PointProps>`
    position: absolute;
    width: 0;
    height: 0;
    top: ${({ position }) => position};
`;

export default AbsolutePoint;
