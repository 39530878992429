import React from "react";
import { Helmet } from "react-helmet";

interface Props {
    appId: string;
    affiliateData?: string;
    appAgrument?: string;
}

const HtmlSmartBanner: React.SFC<Props> = ({
    appId,
    affiliateData,
    appAgrument,
}) => {
    const content = `app-id=${appId}${
        affiliateData ? `, affiliate-data=${affiliateData}` : ""
    }${appAgrument ? `, app-argument=${appAgrument}` : ""}`;

    return (
        <Helmet>
            <meta name="apple-itunes-app" content={content} />
        </Helmet>
    );
};

export default HtmlSmartBanner;
