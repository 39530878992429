const err = (errMsg: string) => {
    throw new Error(errMsg);
};
export const PORT = process.env.PORT || 3000;
export const VITO_APP_ID = "eclipse-guide-landing";
export const API_URL =
    process.env.REACT_APP_API_URL ||
    err("REACT_APP_API_URL is required env var.");
export const ASSETS_URL =
    process.env.REACT_APP_ASSETS_URL ||
    err("REACT_APP_ASSETS_URL is required env var.");
export const DOMAIN_URL =
    process.env.REACT_APP_DOMAIN_URL ||
    err("REACT_APP_DOMAIN_URL is required env var.");
export const MAIN_TITLE =
    process.env.REACT_APP_MAIN_TITLE ||
    err("REACT_APP_MAIN_TITLE is required env var.");
export const APPLE_APP_ID =
    process.env.REACT_APP_APPLE_APP_ID ||
    err("REACT_APP_APPLE_APP_ID is required env var.");
export const GOOGLE_PLAY_APP_ID =
    process.env.REACT_APP_GOOGLE_PLAY_APP_ID ||
    err("REACT_APP_GOOGLE_PLAY_APP_ID is required env var.");
export const FACEBOOK_APP_ID =
    process.env.REACT_APP_FACEBOOK_APP_ID ||
    err("REACT_APP_FACEBOOK_APP_ID is required env var.");
export const GOOGLE_TAG_CONTAINER_ID =
    process.env.REACT_APP_GOOGLE_TAG_CONTAINER_ID ||
    err("REACT_APP_GOOGLE_TAG_CONTAINER_ID is required env var.");
