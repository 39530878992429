import React from "react";
import Helmet from "react-helmet";

interface Props {
    value: string;
}

export const HtmlCanonicalLink: React.SFC<Props> = ({ value }) => (
    <Helmet>
        <link rel="canonical" href={value} />
    </Helmet>
);

export default HtmlCanonicalLink;
