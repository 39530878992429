import React from "react";
import { WIDTH } from "../../config/styles";
import { IImage } from "../../models/gallery";
import ImageLoader from "../main/ImageLoader";

interface Props {
    image: IImage | null;
    srcSetSizes: SrcSetSizes;
    alt?: string;
    showPlaceholder?: boolean;
}

export interface SrcSetSizes {
    phone?: number;
    mediumPhone?: number;
    tablet?: number;
    laptop?: number;
    desktop?: number;
}

export const makeBreakpoint = (breakpoint: number, width?: number): string =>
    width ? `(min-width: ${breakpoint}px) ${width}px, ` : "";

const ImageItem: React.FC<Props> = ({
    image,
    srcSetSizes,
    alt,
    showPlaceholder,
    children,
}) => {
    if (!image) return null;

    const { name, sourceUrl, thumbnails } = image.content;

    if (!thumbnails[0]) return null;

    const { width, height } = thumbnails[0];

    const src = `${sourceUrl}${thumbnails[0].fileName}`;

    const { desktop, laptop, tablet, mediumPhone, phone } = srcSetSizes;

    const sizes = [
        makeBreakpoint(WIDTH.desktop, desktop),
        makeBreakpoint(WIDTH.laptop, laptop),
        makeBreakpoint(WIDTH.tablet, tablet),
        makeBreakpoint(WIDTH.mediumPhone, mediumPhone),
        phone ? `${phone}px` : `100vw`,
    ].join("");

    const srcSet = thumbnails
        .map(
            thumbnail =>
                `${sourceUrl}${thumbnail.fileName} ${thumbnail.width}w`,
        )
        .join(", ");

    return (
        <ImageLoader
            src={src}
            aspectRatio={`${width}:${height}`}
            srcSet={srcSet}
            sizes={sizes}
            showPlaceholder={showPlaceholder}
            alt={alt || name || undefined}
        >
            {children}
        </ImageLoader>
    );
};

export default ImageItem;
