import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import { motion, useViewportScroll, useTransform } from "framer-motion";
import mediaQueries from "../../helpers/mediaQueries";
// import { ASSETS_URL } from "../../../config/env";
import { MoonRaw } from "../images/MoonRaw";

export const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState(0);

    useEffect(() => {
        if (typeof window === "undefined") {
            return;
        }

        const handleResize = () => {
            setWindowSize(window.innerHeight);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount and unmount

    return windowSize;
};

const MoonEclipse: React.SFC = () => {
    const skyRef = useRef<HTMLDivElement>(null);
    const [height, setHeight] = useState<number>(0);
    const { scrollY } = useViewportScroll();

    const windowSize = useWindowSize();

    const rotate = useTransform(
        scrollY,
        [0, height * 0.4, height * 0.4, height * 0.95],
        [45, 5, 5, 0],
    );
    const rotateImage = useTransform(
        scrollY,
        [0, height * 0.4, height * 0.4, height * 0.95],
        [4.5, 0.5, 0.5, 0],
    );
    const opacity = useTransform(
        scrollY,
        [height * 0.85, height * 0.95],
        [0, 1],
    );

    useEffect(() => {
        if (skyRef.current) {
            setHeight(skyRef.current.clientHeight * 2);
        }
    }, [windowSize]);

    return (
        <SkyContainer ref={skyRef}>
            <svg viewBox="0 0 200 200" width="200" height="200">
                <defs>
                    <motion.clipPath id="mask">
                        <circle r="100" cx="100" cy="100" />
                    </motion.clipPath>
                </defs>
                <g clipPath="url(#mask)">
                    <motion.image
                        x="0"
                        y="0"
                        width="200"
                        height="200"
                        style={{
                            rotate: rotateImage,
                        }}
                        xlinkHref={MoonRaw}
                    />
                    <motion.circle
                        r="630"
                        cx="-50"
                        cy="100"
                        fill="#00000030"
                        style={{
                            rotate,
                            originX: "-800px",
                            originY: "-800px",
                        }}
                    />
                    <motion.circle
                        r="250"
                        cx="-50"
                        cy="100"
                        fill="#00000090"
                        style={{
                            rotate,
                            originX: "-800px",
                            originY: "-800px",
                        }}
                    />
                    <motion.circle
                        r="100"
                        cx="100"
                        cy="100"
                        fill="#ff440040"
                        style={{ opacity }}
                    />
                </g>
            </svg>
        </SkyContainer>
    );
};

// Styles for styled-components
const SkyContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: calc(100vh - 3rem);
    display: flex;
    justify-content: center;
    align-items: center;
    will-change: opacity;
    transition: opacity 100ms;
    transform: translateZ(0);
    transform-style: preserve-3d;
    backface-visibility: hidden;
    perspective: 1000;

    ${mediaQueries.landscapePhone(`padding-left: 50%;`)}
`;

export const Sky = styled(motion.div)`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
    will-change: opacity;
    transform: translateZ(0);
    transform-style: preserve-3d;
    backface-visibility: hidden;
    perspective: 1000;
    background-color: ${({ theme }) => theme.colors.blueSky};
`;

export default MoonEclipse;
