import React, { useContext } from "react";
import styled from "styled-components";

// Helpers:
import mediaQueries from "../../helpers/mediaQueries";
import Container from "../grid/Container";
import ImageLoader from "./ImageLoader";
import { Content } from "../AppRoot";
import { FEATURE_ICONS_PATH } from "../../config/content";

const FeaturesSection: React.SFC = () => {
    const { featureIcons, dict } = useContext(Content);
    return (
        <Container id="features-section">
            <FeaturesContainer>
                {featureIcons.map(({ caption, description, icon }) => (
                    <Feature key={caption}>
                        <ImageContainer>
                            <ImageLoader
                                src={`${FEATURE_ICONS_PATH}${icon}.png`}
                                aspectRatio="1:1"
                                alt={`${caption} ${dict.icon}`}
                            />
                        </ImageContainer>
                        <Caption>{caption}</Caption>
                        <Description>{description}</Description>
                    </Feature>
                ))}
            </FeaturesContainer>
        </Container>
    );
};

// Styles for styled-components
const FeaturesContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: baseline;
    margin: 1rem 0 0;
`;

const Feature = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 0.75rem;
    width: 100%;
    ${mediaQueries.tablet(`
        width: 50%;
    `)}
    ${mediaQueries.laptop(`width: 20%;`)}
`;

const ImageContainer = styled.div`
    width: 100%;
    max-width: 200px;
    max-height: 200px;
    margin: 0 0 1rem;
`;

const Caption = styled.p`
    text-align: center;
    margin: 0 0 0.25rem;
    font-size: 1.25rem;
    font-weight: 600;
`;

const Description = styled.p`
    margin: 0;
    text-align: center;
    max-width: 250px;
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.secondary};
`;

export default FeaturesSection;
