import styled from "styled-components";

// Helpers:
import mediaQueries from "../../helpers/mediaQueries";

const Container = styled.div`
    padding-right: 1rem;
    padding-left: 1rem;
    margin-right: auto;
    margin-left: auto;

    width: 100%;

    display: flex;
    flex: 1;
    flex-direction: column;

    ${mediaQueries.tablet(`max-width: 720px;`)}
    ${mediaQueries.laptop(`max-width: 960px;`)}
    ${mediaQueries.desktop(`max-width: 1140px;`)}
`;

export default Container;
