import React from "react";
import { Helmet } from "react-helmet";
interface OGImage {
    url: string;
    width: number;
    height: number;
}

interface Props {
    url: string;
    title: string;
    description: string;
    ogImage: OGImage | null;
}

const HtmlOpenGraph: React.SFC<Props> = ({
    url,
    title,
    description,
    ogImage,
}) => {
    return (
        <>
            <Helmet>
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="Eclipse Guide App" />
                <meta property="og:url" content={url} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
            </Helmet>
            {ogImage && (
                <Helmet>
                    <meta property="og:image" content={ogImage.url} />

                    <meta
                        property="og:image:secure_url"
                        content={ogImage.url}
                    />
                    <meta property="og:image:type" content="image/jpeg" />

                    <meta
                        property="og:image:width"
                        content={`${ogImage.width}`}
                    />
                    <meta
                        property="og:image:height"
                        content={`${ogImage.height}`}
                    />
                    <meta property="og:image:alt" content={description} />
                </Helmet>
            )}
        </>
    );
};

export default HtmlOpenGraph;
