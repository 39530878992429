import { DefaultTheme } from "styled-components";

export const theme1: DefaultTheme = {
    name: "theme1",
    colors: {
        background: "rgb(25,49,102)",
        blueSky: "#0391DE",
        accent: "#fe2b39",
        gradient: "#ff7139",
        primary: "#fff",
        placeholder: "#949dc6",
        secondary: "#949dc6",
        nextButton: "#D2323F",
        actionButton: "#0071e3",
    },
};

export const theme2: DefaultTheme = {
    name: "theme2",
    colors: {
        background: "#00002A",
        blueSky: "#0391DE",
        accent: "#fe2b39",
        gradient: "#ff7139",
        primary: "#fff",
        placeholder: "#949dc6",
        secondary: "#949dc6",
        nextButton: "#D2323F",
        actionButton: "#0071e3",
    },
};
