import React from "react";
import { Helmet } from "react-helmet";

interface Props {
    value: string;
}

const HtmlTitle: React.SFC<Props> = ({ value }) => (
    <Helmet>
        <title>{value}</title>
    </Helmet>
);

export default HtmlTitle;
