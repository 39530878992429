import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
    body {
        background-color: ${({ theme }) => theme.colors.background};
        color: ${({ theme }) => theme.colors.primary};
        font-family: Lato, sans-serif;
    }

    #root {
        display: flex;
        flex-flow: column;
        min-height: 100vh;
        width: 100%;
    }

    * {
        box-sizing: border-box;
    }
`;

export default GlobalStyles;
