import React, { useContext, useState } from "react";
import styled from "styled-components";
import StoreBadges from "./StoreBadges";
import Container from "../grid/Container";
import Countdown from "./Countdown";
import mediaQueries from "../../helpers/mediaQueries";

import SolarEclipse from "./SolarEclipse";
import { Eclipse } from "../../graphql/queries/common";
import { Content } from "../AppRoot";
import { MAIN_TITLE, APPLE_APP_ID, GOOGLE_PLAY_APP_ID } from "../../config/env";
import { makeStoreBadges } from "../../helpers/utils";
import MoonEclipse from "./MoonEclipse";
import ActionsTrigger from "./getAppButton/ActionsTrigger";
import ScrollArrow from "./ScrollArrow";
import { ConfigContext } from "../ConfigContextProvider";
import { ECLIPSE_CHANGE_DELAY } from "../../config/animation";

interface Props {}

const AnimationSection: React.SFC<Props> = () => {
    const now = Date.now();
    const {
        config: { eclipses },
    } = useContext(ConfigContext);

    const nextEclipse = eclipses.find(
        eclipse => eclipse.timestamp + ECLIPSE_CHANGE_DELAY > now,
    );

    return (
        <Section id="animation-section">
            <StickyContainer>
                <ContentBlock nextEclipse={nextEclipse} />
                {!nextEclipse || nextEclipse.type.includes("Solar") ? (
                    <SolarEclipse />
                ) : (
                    <MoonEclipse />
                )}
            </StickyContainer>
        </Section>
    );
};

interface ContentBlockProps {
    nextEclipse?: Eclipse;
}

const ContentBlock: React.FC<ContentBlockProps> = ({ nextEclipse }) => {
    const [isVisible] = useState(true);
    const {
        countdown,
        code,
        animationSection: { subtitle },
        storeBadges,
    } = useContext(Content);

    return (
        <Container>
            {/* <Row>
                <Col xl={6} lg={6} md={6}> */}
            <ContentBox>
                <ActionsTrigger>
                    <Title nextEclipse={!!nextEclipse}>{MAIN_TITLE}</Title>
                </ActionsTrigger>
                <SubTitle isOrange={false}>{subtitle}</SubTitle>
                {nextEclipse && (
                    <Countdown
                        isVisible={isVisible}
                        text={countdown[nextEclipse.type]}
                        timestamp={nextEclipse.timestamp}
                    />
                )}
                <StoreBadgesContainer>
                    <ScrollArrow />
                    <StoreBadges
                        storeBadges={makeStoreBadges(
                            code,
                            storeBadges,
                            {
                                iosAppId: APPLE_APP_ID,
                                androidAppId: GOOGLE_PLAY_APP_ID,
                            },
                            "btn",
                            "animation",
                        )}
                    />
                </StoreBadgesContainer>
            </ContentBox>
            {/* </Col> */}
            {/* </Row> */}
        </Container>
    );
};

// Styles for styled-components

const ContentBox = styled.div`
    height: calc(100vh - 3rem);
    width: 100%;
    z-index: 100;
    display: flex;
    flex-direction: column;

    ${mediaQueries.tablet(`justify-content: center;`)}
`;

const StoreBadgesContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;

    ${mediaQueries.mediumPhone(`
        padding-bottom: 50px;

    `)}

    ${mediaQueries.landscapePhone(`
        flex: 0;
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: 0;
    `)}
`;

const Section = styled.section`
    display: flex;
    width: 100%;
    position: relative;
    height: 300vh;
`;

const StickyContainer = styled.div`
    position: sticky;
    top: 3rem;
    display: flex;
    z-index: 10;
    width: 100%;
    height: calc(100vh - 48px);
    min-height: 500px;
`;

const Title = styled.h1<{ nextEclipse: boolean }>`
    padding: 0;
    color: #fff;
    margin: ${({ nextEclipse }) =>
        `${nextEclipse ? "0.5rem 0 0" : "4rem 0 0"}`};
    font-size: 2rem;
    text-align: center;
    ${({ nextEclipse }) =>
        mediaQueries.mediumPhone(`
        font-size: 2.5rem;
        margin: ${nextEclipse ? "1rem 0 0" : "4rem 0 0"};
    `)}
    ${mediaQueries.landscapePhone(`text-align: left;`)}
    ${mediaQueries.laptop(`font-size: 3rem;`)}
`;

interface SubTitleProps {
    isOrange: boolean;
}

const SubTitle = styled.h3<SubTitleProps>`
    padding: 0;
    margin: 0.5rem auto 0;
    text-align: center;

    color: ${({ theme, isOrange }) =>
        isOrange ? theme.colors.gradient : theme.colors.primary};
    font-size: 1rem;
    font-weight: 400;
    ${mediaQueries.mediumPhone(`
        font-size: 1.25rem;
        margin: 1rem auto 0;
    `)}
    ${mediaQueries.landscapePhone(`
    text-align: left;
    margin: 1rem 0;
    max-width: 50%;

    `)}
    ${mediaQueries.tablet(`
    font-size: 1.5rem;
    max-width: 50%;

    `)}
    ${mediaQueries.laptop(`font-weight: 500;`)}
`;

export default AnimationSection;
