import React from "react";

interface Props {
    width?: number | string;
    height?: number | string;
    fill?: string;
}

const Logo: React.SFC<Props> = ({
    width = "51",
    height = "100%",
    fill = "#fff",
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill={fill}
        viewBox="0 0 70 58"
        fillRule="evenodd"
    >
        <path d="M67,31H63a2,2,0,0,1,0-4h4A2,2,0,0,1,67,31ZM59.783,17.1a2,2,0,0,1-2.351-3.236l3.236-2.351a2,2,0,1,1,2.351,3.236ZM40,47a17.9,17.9,0,0,1-7.7-1.75,21.116,21.116,0,0,0,3.036-3.064,14,14,0,1,0,0-26.372A21.116,21.116,0,0,0,32.3,12.75,17.993,17.993,0,1,1,40,47ZM49.01,7.744a2,2,0,0,1-3.8-1.236l1.236-3.8a2,2,0,0,1,3.8,1.236Zm-15.5,1.284a2,2,0,0,1-2.52-1.284l-1.236-3.8a2,2,0,0,1,3.8-1.236l1.236,3.8A2,2,0,0,1,33.511,9.028ZM37,29A18,18,0,1,1,19,11,18,18,0,0,1,37,29ZM30.991,50.256a2,2,0,0,1,3.8,1.236l-1.236,3.8a2,2,0,0,1-3.8-1.236Zm15.5-1.284a2,2,0,0,1,2.52,1.284l1.236,3.8a2,2,0,0,1-3.8,1.236l-1.236-3.8A2,2,0,0,1,46.489,48.972ZM59.783,40.9l3.236,2.351a2,2,0,1,1-2.351,3.236l-3.236-2.351A2,2,0,0,1,59.783,40.9Z" />
    </svg>
);

export default Logo;
