import React from "react";
import { Helmet } from "react-helmet";

interface Props {
    gTag: string;
}

export const HtmlGTag: React.SFC<Props> = ({ gTag }) => {
    const gtagSnippet = `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${gTag}');
`;
    // TODO: add google tag noscript snippet
    return (
        <Helmet>
            <script>{gtagSnippet}</script>
        </Helmet>
    );
};

export default HtmlGTag;
