// Constants:
import { ASSETS_URL } from "../config/env";

// Types & Interfaces:
import { StoreBadgesAlts, Lang } from "../i18n/content";
import { StoreBadge } from "../components/main/StoreBadges";
import { Thumbnail } from "../models/gallery";

const convertToAppstoreLang = (lang: Lang): string => {
    switch (lang) {
        case "en":
            return "";
        case "ja":
            return "/jp";
        case "ko":
            return "/kr";
        case "zh-Hans":
            return "/cn";
        case "zh-Hant":
            return "/tw";
        default:
            return "/" + lang;
    }
};

export const makeAppstoreLink = (
    appId: string,
    lang: Lang,
    location: string,
    item: string,
): URL => {
    const url = new URL(`https://itunes.apple.com`);
    url.pathname = `${convertToAppstoreLang(lang)}/app/apple-store/id${appId}`;
    url.searchParams.set("mt", "8"); // mt equals 8 means that it is mobile application
    url.searchParams.set("pt", "19853"); // 19853 - it is Vito Technology's AppStore ID it is forever constant
    url.searchParams.set("ct", `eg-landing/${location}:${item}`);
    return url;
};

export const makeGooglePlayLink = (
    appId: string,
    lang: Lang,
    location: string,
    item: string,
): URL => {
    const url = new URL(`https://play.google.com/store/apps/details`);
    url.searchParams.set("id", appId);
    if (lang !== "en") {
        url.searchParams.set("hl", lang);
    }
    url.searchParams.set(
        "referrer",
        `utm_source=eg-landing&utm_medium=${item}&utm_campaign=${location}`,
    );
    return url;
};

interface StoreAppIds {
    iosAppId?: string;
    androidAppId?: string;
}

export const makeStoreBadges = (
    lang: Lang,
    { appStoreAlt, googlePlayAlt }: StoreBadgesAlts,
    { iosAppId, androidAppId }: StoreAppIds,
    location: string,
    item: string,
): StoreBadge[] => {
    const storeBadges = [];

    if (iosAppId) {
        storeBadges.push({
            storeLink: makeAppstoreLink(
                iosAppId,
                lang,
                location,
                item,
            ).toString(),
            storeImage: `${ASSETS_URL}/images/badges/${lang}/appstore.svg`,
            storeImageAlt: appStoreAlt,
            id: `appStore_${location}_${item}`,
        });
    }
    if (androidAppId) {
        storeBadges.push({
            storeLink: makeGooglePlayLink(
                androidAppId,
                lang,
                location,
                item,
            ).toString(),
            storeImage: `${ASSETS_URL}/images/badges/${lang}/googleplay.svg`,
            storeImageAlt: googlePlayAlt,
            id: `googlePlay_${location}_${item}`,
        });
    }
    return storeBadges;
};

export const preserveSearch = (search: string, param?: string): string => {
    const searchParams = new URLSearchParams(search);

    const params: string[] = [];

    if (param) {
        params.push(param);
    }
    const ver = searchParams.get("ver");
    if (ver === "2") {
        params.push("ver=2");
    }
    if (params.length > 0) {
        return `?${params.join("&")}`;
    }
    return "";
};
type AppPlatform = "ios" | "android" | "huawei";
export const getDevicePlatform = (): AppPlatform | null => {
    try {
        if (typeof window !== "undefined" && typeof navigator !== "undefined") {
            const userAgent =
                navigator.userAgent || navigator.vendor || navigator.platform;

            if (/android/i.test(userAgent)) {
                return "android";
            }

            if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                return "ios";
            }

            return null;
        }
        return null;
    } catch (e) {
        return null;
    }
};

export const isClient = (): boolean => {
    if (typeof window !== "undefined") {
        return true;
    }
    return false;
};
export const abbrevieteNumber = (value: number): string => {
    const suffixes = ["", "K", "M", "G", "T", "P", "E"];
    if (value < 1000) {
        return `${value}`;
    }
    let newValue = value;
    let suffixIndex = 0;
    while (newValue >= 1000) {
        newValue /= 1000;
        suffixIndex++;
    }
    const fixed = newValue.toFixed(1);
    const floored = Math.floor(newValue);
    const final =
        `${floored}`.length <= 1 && Number(fixed) > floored ? fixed : floored;
    return `${final}${suffixes[suffixIndex]}`;
};

export const findClosestThumbnail = (
    thumbnails: Thumbnail[],
    minWidth: number = 1200,
): Thumbnail => {
    const thumbnail = thumbnails.reduce<Thumbnail>((prev, current) => {
        if (
            Math.abs(current.width - minWidth) < Math.abs(prev.width - minWidth)
        ) {
            return current;
        } else {
            return prev;
        }
    }, thumbnails[0]);
    return thumbnail;
};
