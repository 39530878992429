import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";

// Helpers:
import mediaQueries from "../../helpers/mediaQueries";
import { ECLIPSE_CHANGE_DELAY } from "../../config/animation";
// import { solarEclipseSteps, ANIMATION_LENGTH } from "../../../config/animation";

interface CounterState {
    days: number;
    hours: number;
    min: number;
    sec: number;
}

export interface Props {
    text: string;
    timestamp: number;
    isVisible: boolean;
}

const useInterval = (callback: () => void, delay: number) => {
    const savedCallback = useRef<() => void>(callback);

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
    }, [delay]);
};

const Countdown: React.SFC<Props> = ({ text, timestamp, isVisible }) => {
    const [counterState, setCounterState] = useState<CounterState | null>(null);

    useInterval(() => {
        const distance = timestamp - Date.now();
        if (distance > 0) {
            setCounterState({
                days: Math.floor(distance / (1000 * 60 * 60 * 24)),
                hours: Math.floor(
                    (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
                ),
                min: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
                sec: Math.floor((distance % (1000 * 60)) / 1000),
            });
        } else if (distance < 0 && distance * -1 < ECLIPSE_CHANGE_DELAY) {
            setCounterState({
                days: 0,
                hours: 0,
                min: 0,
                sec: 0,
            });
        } else {
            setCounterState(null);
        }
    }, 1000);

    if (counterState === null) return null;

    const { days, hours, min, sec } = counterState;

    return (
        <Section id="countdown" isVisible={isVisible}>
            <Text>{text}</Text>
            <Counter>
                <DigitsBlock mark="DAYS" value={days} />
                <DigitsBlock mark="HRS" value={hours} />
                <DigitsBlock mark="MIN" value={min} />
                <DigitsBlock mark="SEC" value={sec} />
            </Counter>
        </Section>
    );
};

interface DigitBlockProps {
    mark: string;
    value: number;
}

const DigitsBlock: React.FC<DigitBlockProps> = ({ mark, value }) => {
    const values = `${value}`.padStart(2, "0").split("");
    return (
        <Block>
            <Digits>
                {values.map((v, i) => (
                    <Digit key={mark + i} isRed={mark === "SEC"}>
                        {v}
                    </Digit>
                ))}
            </Digits>
            <Mark>{mark}</Mark>
        </Block>
    );
};

interface SectionProps {
    isVisible: boolean;
}

const Section = styled.section<SectionProps>`
    box-sizing: border-box;
    width: 100%;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 1rem;
    font-weight: 300;
    color: #fff;
    margin: 0.5rem 0;
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    transition: opacity 1s;
    will-change: opacity;
    transform: translateZ(0);

    ${mediaQueries.landscapePhone(`display: none;`)}

    ${mediaQueries.tablet(`font-size: 1.5rem;`)}
`;

const Block = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Digits = styled.div`
    display: flex;
    margin: 5px;
`;

const Mark = styled.div`
    width: 100%;
    font-size: 0.8rem;
    font-weight: 400;
    text-align: center;
    color: ${({ theme }) => theme.colors.primary};
`;

interface DigitProps {
    isRed?: boolean;
}

const Digit = styled.div<DigitProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0;
    margin: 1px;
    width: 20px;
    color: ${({ isRed, theme }) =>
        isRed ? theme.colors.gradient : theme.colors.primary};
    font-size: 1rem;
    height: 25px;
    border-radius: 3px;
    background-color: rgba(170, 200, 255, 0.3);
`;

const Text = styled.div`
    margin: 5px 0px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.primary};
`;

const Counter = styled.div`
    display: flex;
    justify-content: center;
`;

export default Countdown;
