import React from "react";
import styled, { keyframes } from "styled-components";
import mediaQueries from "../../helpers/mediaQueries";

const ScrollArrow = () => {
    return (
        <Wrapper>
            <Arrow />
            <Arrow />
        </Wrapper>
    );
};

const fadeSlideUp = keyframes`
    0% {
      opacity: 0;
      transform: translateY(4rem);
    }
    100% {
      opacity: 1;
      transform: none;
    }
  `;
const swing = keyframes`
    0% {
      opacity: 1;
      transform: none;
    }
    50% {
      opacity: .4;
      transform: translateY(0.625rem);
    }
    100% {
      opacity: 1;
      transform: none;
    }
  `;

const Wrapper = styled.div`
    animation: ${fadeSlideUp} 1s 1s ease-out forwards,
        ${swing} 2s 3s ease-out infinite;
    opacity: 0;
    align-self: center;
    margin: 0;

    & div:nth-child(2) {
        top: -1.5rem;
        position: relative;
    }
    ${mediaQueries.mediumPhone(`
        margin: 0 0 1rem;
    `)}
    ${mediaQueries.landscapePhone(`
      order: 2;
    `)}
`;
const Arrow = styled.div`
    width: 2rem;
    height: 2rem;
    transform: rotate(45deg);
    border-bottom: 2px solid ${({ theme }) => theme.colors.gradient};
    border-right: 2px solid ${({ theme }) => theme.colors.gradient};
`;
export default ScrollArrow;
