import React from "react";
import styled from "styled-components";

import mediaQueries from "../../helpers/mediaQueries";
import socialicons from "../../config/social-icons";
import Icon from "../svg/Icon";
import { getDevicePlatform, isClient } from "../../helpers/utils";
import SharingCounterBadge from "../svg/SharingCounterBadge";
import { EngagementInfo, SocialMedia } from "../../models/_common";

interface ShareLinkProps {
    socialMedia: SocialMedia;
    id: string;
    href?: string;
    value: number;
    count: number | null;
    onClick: () => void;
}

const ShareLink: React.FC<ShareLinkProps> = ({
    socialMedia,
    id,
    href,
    value,
    count,
    onClick,
}) => {
    return (
        <SharingButtonContainer>
            <IconLink
                id={id}
                href={href}
                data-value={value}
                target="_blank"
                rel="noopener"
                onClick={onClick}
            >
                <Icon {...socialicons[socialMedia]} />
            </IconLink>
            <SharingCounterBadge count={count} />
        </SharingButtonContainer>
    );
};

const buildLinkHref = (
    socialMedia: SocialMedia,
    encodedUrl: string,
    encodedPageTitle: string,
) => {
    switch (socialMedia) {
        case "facebook":
            return `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}&quote=${encodedPageTitle}&hashtag=%23eclipseguide`;
        case "messenger":
            return `fb-messenger://share/?link=${encodedUrl}`;
        case "twitter":
            return `https://twitter.com/share?url=${encodedUrl}&text=${encodedPageTitle}&via=StarWalk&hashtags=eclipseguide`;
        case "telegram":
            return `https://telegram.me/share/?url=${encodedUrl}&text=${encodedPageTitle}`;
        case "whatsapp":
            return `https://${
                getDevicePlatform() ? "wa.me/" : "web.whatsapp.com/send"
            }?text=${encodedUrl}`;
        default:
            return undefined;
    }
};

const openNativeSharing = async (data: ShareData) => {
    try {
        if (
            typeof navigator === "object" &&
            typeof navigator.share === "function"
        ) {
            await navigator.share(data);
        }
    } catch (e) {
        console.error(e);
    }
};

const isNativeSharingEnabled = (): boolean => {
    return (
        typeof window !== "undefined" &&
        typeof navigator === "object" &&
        typeof navigator.share === "function"
    );
};
export type NodeWithEngagementInfoTypename = "Quiz" | "Image";

interface Props {
    pageUrl: string;
    shareMessage: string;
    shareCampaign: "share" | "share-quiz" | "share-quiz-result";
    hashAnchor?: string;
    medium?: string;
    value?: number;
    typename: NodeWithEngagementInfoTypename;
    engagementInfo?: EngagementInfo;
}

const ShareButtons: React.FC<Props> = ({
    pageUrl,
    shareMessage,
    shareCampaign,
    medium = "referrer",
    hashAnchor,
    value = 0,
    engagementInfo,
}) => {
    if (!isClient()) {
        return (
            <SharingContainer
                className="share-buttons-container"
                data-nosnippet
            />
        );
    }
    const socialMedias: SocialMedia[] = [
        "facebook",
        "messenger",
        "twitter",
        "telegram",
        "whatsapp",
        "native",
    ];
    const hash = hashAnchor ? `#${hashAnchor}` : "";
    return (
        <SharingContainer className="share-buttons-container" data-nosnippet>
            {isClient() ? (
                <ButtonsContainer>
                    {socialMedias.map(socialMedia => {
                        const trackedPageUrl = `${pageUrl}?utm_source=${socialMedia}&utm_medium=${medium}&utm_campaign=${shareCampaign}${hash}`;
                        let onClick = () => {};
                        if (
                            socialMedia === "native" &&
                            !isNativeSharingEnabled()
                        ) {
                            return null;
                        }
                        if (
                            socialMedia === "native" &&
                            isNativeSharingEnabled()
                        ) {
                            onClick = () => {
                                openNativeSharing({
                                    url: trackedPageUrl,
                                    title: shareMessage,
                                });
                            };
                        }
                        const platform = getDevicePlatform();
                        if (
                            socialMedia === "messenger" &&
                            platform !== "ios" &&
                            platform !== "android"
                        ) {
                            return null;
                        }

                        const props = {
                            key: socialMedia,
                            count: engagementInfo
                                ? engagementInfo[socialMedia]
                                : null,
                            socialMedia,
                            value,
                            id: `${socialMedia}-share-${shareCampaign}-link`,
                            onClick,
                            href: buildLinkHref(
                                socialMedia,
                                encodeURIComponent(trackedPageUrl),
                                encodeURIComponent(shareMessage),
                            ),
                        };
                        return <ShareLink {...props} />;
                    })}
                </ButtonsContainer>
            ) : null}
        </SharingContainer>
    );
};

const SharingContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1.5rem 0;

    ${mediaQueries.laptop(`
        align-items: flex-start;
    `)}
`;

const ButtonsContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 0.25rem 0 1rem;
    transition: all 0.2s ease-in-out;

    & > div:not(:last-child) {
        border-right: 1px solid ${({ theme }) => theme.colors.secondary};
        padding-right: 1px;
    }
`;

const SharingButtonContainer = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    max-width: 3.25rem;
    min-width: 2.25rem;
`;

const IconLink = styled.a`
    display: flex;
    cursor: pointer;
    width: 20px;
    height: 20px;
    color: inherit;
    transition: all 0.2s ease-in-out;
`;

export default ShareButtons;
