import React, { useContext } from "react";
import styled from "styled-components";

// Helpers:
import mediaQueries from "../../helpers/mediaQueries";

// Components:
import Container from "../grid/Container";
// import FacebookLikeButton from "./FacebookLikeButton";

// Types & Interfaces:
import { DOMAIN_URL, MAIN_TITLE } from "../../config/env";
import SWLogo from "../svg/SWLogo";
import ImageItem from "../images/ImageItem";
import { Content } from "../AppRoot";
import ShareButtons from "./ShareButtons";
import { useQuery } from "@apollo/client";
import {
    GetQuizData,
    GetQuizVariables,
    GET_QUIZ,
} from "../../graphql/queries/games";
import { ConfigContext } from "../ConfigContextProvider";

const ProductSection: React.SFC = () => {
    const {
        code,
        productSection: { description, description2 },
        animationSection: { subtitle },
    } = useContext(Content);

    const { mediaItem } = useContext(ConfigContext);

    const { data } = useQuery<GetQuizData, GetQuizVariables>(GET_QUIZ, {
        variables: {
            id: "check-your-knowledge-of-solar-and-lunar-eclipses",
            langCode: code,
        },
        notifyOnNetworkStatusChange: true,
    });
    return (
        <Section>
            <Container id="product-section">
                <ContentContainer>
                    <ProductImageContainer>
                        <ImageItem
                            image={mediaItem}
                            showPlaceholder={false}
                            srcSetSizes={{
                                desktop: 1010,
                                laptop: 928,
                                tablet: 688,
                            }}
                        />
                    </ProductImageContainer>
                    <ShareButtons
                        pageUrl={`${DOMAIN_URL}/${code}`}
                        shareMessage={`${MAIN_TITLE} - ${subtitle}`}
                        shareCampaign="share"
                        typename="Image"
                        engagementInfo={data?.quiz?.engagementInfo}
                    />
                    <Description>{description}</Description>
                    <PublisherSection>
                        <StarWalkLogo>
                            <Link
                                id="starwalk-site-link"
                                href="https://starwalk.space/?utm_medium=publishersection&utm_source=eglanding&utm_campaign=eclipseguide"
                            >
                                <SWLogo />
                                Star Walk 2
                            </Link>
                        </StarWalkLogo>
                        <PublisherContent>{description2}</PublisherContent>
                    </PublisherSection>
                </ContentContainer>
            </Container>
        </Section>
    );
};

// Styles for Styled Components:
const Section = styled.section`
    width: 100%;
    background: ${({ theme }) =>
        `linear-gradient(0deg, ${theme.colors.background} 0%, transparent 100%)`};
`;

const ContentContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 0;
`;

const PublisherSection = styled.div`
    margin: 0 0 1rem 0;
`;

const PublisherContent = styled.div`
    text-align: center;
    padding: 0.5rem;
    margin: 0 auto;
    width: 100%;
    max-width: 560px;
    color: ${({ theme }) => theme.colors.primary};
    font-size: 1rem;
    ${mediaQueries.laptop(`
        font-size: 1.25rem;
    `)}
`;

const StarWalkLogo = styled.div`
    padding: 0.5rem;
    font-weight: 300;
    font-size: 1.5rem;
`;

const Link = styled.a`
    display: flex;
    width: 246px;
    margin: 0 auto;
    color: ${({ theme }) => theme.colors.primary};
    letter-spacing: 1px;
    width: max-content;
    align-items: center;
    text-decoration: none;
`;

const Description = styled.p`
    padding: 0;
    text-align: center;
    margin: 0 0 1rem 0;
    width: 100%;
    max-width: 560px;
    color: ${({ theme }) => theme.colors.primary};
    font-size: 1rem;
    ${mediaQueries.tablet(`
        font-size: 1.25rem;
    `)}
    ${mediaQueries.laptop(`
        font-size: 1.5rem;
    `)}
`;

const ProductImageContainer = styled.div`
    margin: 1rem 0 0;
    width: 100%;
    overflow: hidden;
    border-radius: 20px;
    /* max-width: 540px; */
    /* ${mediaQueries.tablet(`max-width: 550px`)} */
    /* ${mediaQueries.laptop(`max-width: 560px;`)} */
`;

export default ProductSection;
