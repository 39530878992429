import gql from "graphql-tag";

export interface App {
    __typename: "App";
    id: string;
    title: string;
    icon: AppIcon | null;
    marketUrl: string;
    marketId: string;
    platform: AppPlatform;
}
export type AppPlatform = "ios" | "android" | "huawei";

export interface AppIcon {
    id: string;
    sourceUrl: string;
    sizes: string[];
}
export interface GetAppData {
    iosApp: App | null;
    androidApp: App | null;
}

export const GET_APP = gql`
    query getApp {
        iosApp: app(id: "eclipse-ios") {
            id
            title
            icon {
                sourceUrl
                sizes
            }
            marketUrl
            marketId
            platform
        }
        androidApp: app(id: "eclipse-google") {
            id
            title
            icon {
                sourceUrl
                sizes
            }
            marketUrl
            marketId
            platform
        }
    }
`;
