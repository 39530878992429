// Constants with common styles:
export const BACKGROUND_COLOR = "rgb(25,49,102)";
export const BLUE_SKY_COLOR = "#0391DE";
// export const ACCENT_COLOR = "#fe2b39";
// export const GRADIENT_COLOR = "#ff7139";
// export const PRIMARY_COLOR = "#fff";
// export const PLACEHOLDER_COLOR = "#949dc6";
// export const SECONDARY_COLOR = "#949dc6";

// Constant for media queries:
export const WIDTH = {
    // smallPhone: 320,
    mediumPhone: 365,
    // largePhone: 425,
    // landscapePhone: 576,
    tablet: 600,
    laptop: 900,
    desktop: 1200,
};

export const STAR_FIELD_DIAMETER = 1200;

export const BRIGHT_STARS_COUNT = 50;

export const STARS_COUNT = 150;

export const MOON_DIAMETER = 200;
