import React, { useReducer } from "react";

interface PromoAppState {
    isButtonVisible: boolean;
}

type PromoAppAction = { type: "show" } | { type: "hide" };

type PromoAppSetter = (
    prevValue: PromoAppState,
    action: PromoAppAction,
) => PromoAppState;

type Dispatch = (action: PromoAppAction) => void;

export const PromoAppSetterContext = React.createContext<Dispatch>(() => {});
export const GetButtonVisibilityContext = React.createContext<boolean>(false);

const promoAppSetter = (
    state: PromoAppState,
    action: PromoAppAction,
): PromoAppState => {
    switch (action.type) {
        case "show":
            return {
                ...state,
                isButtonVisible: true,
            };
        case "hide":
            return {
                ...state,
                isButtonVisible: false,
            };
        default:
            throw new Error("Invalid action type in promoAppSetter");
    }
};

const PromoAppContextProvider: React.FC = ({ children }) => {
    const [{ isButtonVisible }, pickPromoApp] = useReducer<
        PromoAppSetter,
        PromoAppState
    >(
        promoAppSetter,
        {
            isButtonVisible: false,
        },
        () => ({ isButtonVisible: false }),
    );
    return (
        <PromoAppSetterContext.Provider value={pickPromoApp}>
            <GetButtonVisibilityContext.Provider value={isButtonVisible}>
                {children}
            </GetButtonVisibilityContext.Provider>
        </PromoAppSetterContext.Provider>
    );
};

export default PromoAppContextProvider;
