import { useQuery } from "@apollo/client";
import React, { useContext } from "react";
import styled from "styled-components";

import mediaQueries from "../../../helpers/mediaQueries";
import { Content } from "../../AppRoot";
import { GetButtonVisibilityContext } from "./PromoAppContextProvider";
import { App, GetAppData, GET_APP } from "../../../graphql/queries/app";
import { getDevicePlatform } from "../../../helpers/utils";

const ButtonActions: React.FC = () => {
    const { data } = useQuery<GetAppData>(GET_APP);

    const platform = getDevicePlatform();

    const apps = [data?.iosApp, data?.androidApp];

    const app = apps.find(a => a?.platform === platform) || null;
    const isShown = useContext(GetButtonVisibilityContext);

    return (
        <ButtonActionsContainer id="button-actions-container">
            {app ? <GetAppButtonBlock app={app} isShown={isShown} /> : null}
        </ButtonActionsContainer>
    );
};

interface GetAppButtonBlockProps {
    app: App;
    isShown: boolean;
}

const visibleGetAppButtonClassName = "get-app-button-visible";

const GetAppButtonBlock: React.FC<GetAppButtonBlockProps> = ({
    app,
    isShown,
}) => {
    const { getAppButton } = useContext(Content);

    const getAppButtonText = getAppButton.replace(
        "%{APP_NAME}",
        "Eclipse Guide",
    );

    return (
        <ButtonBlock
            id="get-app-button-block"
            className={isShown ? visibleGetAppButtonClassName : undefined}
        >
            <GetAppButton id="get-app-btn" href={app.marketUrl}>
                {getAppButtonText}
            </GetAppButton>
        </ButtonBlock>
    );
};
interface ButtonProps {
    isSkyTonightButton?: boolean;
}
const ButtonBlock = styled.div<ButtonProps>`
    height: 3rem;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    transition: transform 0.4s ease-in-out;
    transform: translateY(0);
    input#menu-state:checked ~ div & {
        transform: translateY(0);
        transition: transform 0.2s;
    }
    &.${visibleGetAppButtonClassName} {
        transform: translateY(-3.7rem);
    }
`;

const GetAppButton = styled.a`
    border: 0;
    background-color: ${({ theme }) => theme.colors.actionButton};
    color: ${({ theme }) => theme.colors.primary};
    height: 2.5rem;
    font-size: 0.9rem;
    filter: drop-shadow(0px 9px 15px rgba(0, 0, 0, 0.9));
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 2rem;
    margin: 0 auto;
    border-radius: 2rem;
    text-decoration: none;
`;
const ButtonActionsContainer = styled.div<ButtonProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 0px;
    z-index: 1000;
    width: 100%;
    height: 0;
    overflow: visible;
    transition: top 0s;
    transition-delay: 1s;
    ${mediaQueries.laptop(`
        display: none;
    `)}
`;

export default ButtonActions;
