import React, { useContext } from "react";
import styled from "styled-components";

import mediaQueries from "../../helpers/mediaQueries";
import Container from "../grid/Container";
import { Content } from "../AppRoot";
import ImageItem from "../images/ImageItem";
import { ConfigContext } from "../ConfigContextProvider";

const GallerySection: React.SFC = () => {
    const {
        gallerySection: { title, subtitle },
    } = useContext(Content);

    const { mediaItems } = useContext(ConfigContext);
    return (
        <Container id="gallery-section">
            <Section>
                <Title>{title}</Title>
                <Subtitle>{subtitle}</Subtitle>
                {mediaItems.map((image, index) => (
                    <ImageContainer
                        key={image?.id || `gallery-section-item-${index}`}
                    >
                        <ImageItem
                            image={image}
                            showPlaceholder={false}
                            srcSetSizes={{
                                desktop: 320,
                                laptop: 275,
                                tablet: 172,
                            }}
                        />
                    </ImageContainer>
                ))}
            </Section>
        </Container>
    );
};

// Styles for Styled Components:
const Section = styled.section`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 2.5rem 0 1.5rem;
`;

const Title = styled.h2`
    width: 100%;
    text-align: center;
    margin: 0 0 1rem;
    font-size: 1.75rem;
    font-weight: 500;
    ${mediaQueries.laptop(`font-size: 2.5rem;`)}
`;

const Subtitle = styled.h3`
    width: 100%;
    text-align: center;
    color: ${({ theme }) => theme.colors.secondary};
    margin: 0 0 1rem;
    font-size: 1rem;
    font-weight: 400;
    ${mediaQueries.tablet(`font-size: 1.25rem;`)}
`;

const ImageContainer = styled.div`
    height: 100%;
    margin: 0.5rem;
    width: calc(50% - 1rem);
    border-radius: 8% / 4.5%;
    overflow: hidden;
    ${mediaQueries.tablet(`
        width: calc(33% - 1rem);
        max-width: 20rem;
    `)}
`;

export default GallerySection;
