import React, { useContext } from "react";
import { Content } from "./AppRoot";
import { MAIN_TITLE, DOMAIN_URL, APPLE_APP_ID } from "../config/env";
import HtmlTitle from "./helmet/HtmlTitle";
import HtmlDescription from "./helmet/HtmlDescription";
import HtmlLang from "./helmet/HtmlLang";
import HtmlCanonicalLink from "./helmet/HtmlCanonicalLink";
import HtmlSmartBanner from "./helmet/HtmlSmartBanner";
// import HtmlFacebookSDK from "./helmet/HtmlFacebookSDK";
import HtmlOpenGraph from "./helmet/HtmlOpenGraph";
import HtmlTwitter from "./helmet/HtmlTwitter";
import HtmlAlternateLinks from "./helmet/HtmlAlternateLinks";
import { ConfigContext } from "./ConfigContextProvider";

import { findClosestThumbnail } from "../helpers/utils";
import { IImage } from "../models/gallery";

const makeOgImage = (mediaItem: IImage | null) => {
    if (!mediaItem) return null;

    const ogImageThumbnail = findClosestThumbnail(mediaItem.content.thumbnails);
    const ogImageSourceUrl = mediaItem.content.sourceUrl;
    return {
        url: ogImageSourceUrl + ogImageThumbnail.fileName,
        width: ogImageThumbnail.width,
        height: ogImageThumbnail.height,
    };
};
const MetaTags: React.FC = () => {
    const {
        animationSection: { subtitle },
        meta: { description },
        productSection: { description2 },
        code,
    } = useContext(Content);

    const { mediaItem } = useContext(ConfigContext);

    const title = `${MAIN_TITLE} - ${subtitle}`;
    const canonicalLink = `${DOMAIN_URL}/${code}`;
    const ogImage = makeOgImage(mediaItem);

    return (
        <>
            <HtmlTitle value={title} />
            <HtmlDescription value={description2} />
            <HtmlLang lang={code} />
            <HtmlCanonicalLink value={canonicalLink} />
            <HtmlSmartBanner appId={APPLE_APP_ID} />
            {/* <HtmlFacebookSDK /> */}
            <HtmlOpenGraph
                title={title}
                description={description}
                url={canonicalLink}
                ogImage={ogImage}
            />
            <HtmlTwitter
                title={title}
                description={description}
                openGraphTwitterImageURL={ogImage?.url}
            />
            <HtmlAlternateLinks domainURL={DOMAIN_URL} />
        </>
    );
};

export default MetaTags;
