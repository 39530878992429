import styled, { ThemeContext } from "styled-components";
import React, { useContext } from "react";
import Logo from "../svg/Logo";

interface Props {
    aspectRatio: string;
    src?: string;
    srcSet?: string;
    sizes?: string;
    alt?: string;
    showPlaceholder?: boolean;
}

const ImageLoader: React.FC<Props> = ({
    src,
    aspectRatio,
    alt,
    srcSet,
    sizes,
    showPlaceholder = true,
}) => {
    const { colors } = useContext(ThemeContext);
    const [width, height] = aspectRatio.split(":").map(elem => Number(elem));
    const paddind = (height / width) * 100;

    return (
        <AspectRatio padding={paddind}>
            {showPlaceholder && (
                <ImagePlaceholder>
                    <Logo width={"60%"} fill={colors.placeholder} />
                </ImagePlaceholder>
            )}
            <Img src={src} alt={alt} srcSet={srcSet} sizes={sizes} />
        </AspectRatio>
    );
};

const ImagePlaceholder = styled.div`
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.4;
    color: ${({ theme }) => theme.colors.secondary};
`;

const Img = styled.img`
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
`;

interface AspectRatioProps {
    padding: number;
}
const AspectRatio = styled.div<AspectRatioProps>`
    width: 100%;
    height: 0;
    position: relative;
    padding-bottom: ${({ padding }) => `${padding}%`};
    overflow: hidden;
`;

export default ImageLoader;
